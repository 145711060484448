<script>
import MediumEditor from "@lib/ckeditor/build/ckeditormedium.js";
import CKEditor from "@ckeditor/ckeditor5-vue";

export default {
  components: { ckeditor: CKEditor.component },
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {},
    className: {
      type: String,
      required: false,
    },
    validator: {
      type: Object,
      required: false,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      editor: MediumEditor,
      editorConfig: {
        placeholder: this.label,
        shouldNotGroupWhenFull: true,
      },
    };
  },
  methods: {},
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      },
      set: function (val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
};
</script>

<template>
  <div :class="this.className">
    <ckeditor
      :editor="editor"
      v-model="currentValue"
      :config="editorConfig"
      :class="this.className"
      :disabled="this.disabled"
    ></ckeditor>
    <small v-if="isValidatorRequired" class="p-error">
      Le champ {{ this.label }} est requis</small
    >
  </div>
</template>
